import BackBtn from 'components/global/BackBtn';
import SmallCarImg from 'assets/images/smallCar.png';
import keysImg from 'assets/images/keys.png';
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { useQuery } from 'react-query';
import fetcher from 'utils/fetcher';
import Axios, { baseURL } from 'config/api';
import LocationLink from 'components/global/LocationLink';
import Loader from 'components/global/Loader';
import calculateDuration from 'utils/calculateDuration';
import CarInfo from 'components/bookings/CarInfo';
import PickupInstructions from 'components/bookings/PickupInstructions';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import FutureBookings from 'components/bookings/FutureBookings';
import Reviews from 'components/vehicles/Reviews';
import ApplyCoupon from 'components/bookings/ApplyCoupon';
import DatePicker from 'react-datepicker'
import getBookedDates from 'utils/getBookedDates';
import ItemNotFound from 'components/global/ItemNotFound';
import PickupDate from 'components/bookings/PickupDate';
import ReturnDate from 'components/bookings/ReturnDate';
import { useAuthPopupContext } from 'context/AuthPopupContext';
import formatAmount from 'utils/formatAmount';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip';
import { date } from 'yup';
import formatDateForInput from 'utils/formatDateForInput';
import useRazorpay from "react-razorpay";
import saveBookingDetails from 'utils/saveBookingDetails';
import { calculateBooking, reApplyCoupon } from 'redux/actions/bookingActions';
import calculateCODAmountToPayOnline from 'utils/calculateCODAmoutToPayOnline';
import Breadcrumbs from 'components/global/Breadcrumbs';
import Insurance from 'components/bookings/Insurance';
import { setUser } from 'redux/reducers/authReducer';


const initState = {
    pickupDate : '' ,
    returnDate : '',
    duration : {
        days : 0,
        hours : 0,
        minutes : 0 ,
        totalHours : 0
    } , 
    totalPrice : 0,
    paymentMethod : 'online' , 
    GST : 0 ,
    withDriver : false ,
    driverRent : 0 ,
    vehicleRent : 0 ,
    amountToPayOnline : 0 ,
    insurance : '' ,
    insuranceCost : '',
    usedWalletBalance : 0
};

const crumbs = [
    { label: "Home", path: "/" },
    { label: "Vehicles", path: "/vehicles" },
    { label: "Book Now",  },
]

const BookNow = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const { setShowAuthPopup , setCurrentStep } = useAuthPopupContext();
    const { couponLoading , calculateLoading } = useSelector(state => state.booking);

    const [Razorpay] = useRazorpay();

    const [carDetails , setCarDetails] = useState('');
    const [reviews , setReviews] = useState('');
    const [futureBookings , setFutureBookings] = useState([]);
    const [settings , setSettings] = useState('');
    const [insurancePlans , setInsurancePlans] = useState(null);
    const [isTermsAccepted , setIsTermsAccepted] = useState(false);
    const [useWalletBalance , setUseWalletBalance] = useState(false);

    const [bookingLoading , setBookingLoading] = useState(false);
    const [bookingData , setBookingData] = useState(initState);

    const [discountDetails , setDiscountDetails] = useState({
        coupon : '' ,
        actualAmount : 0 ,
        discountAmount : 0 ,
        isCouponApplied : false ,
        discount : 0
    });

    const { user } = useSelector(state => state.auth);
    const queryKey = ['fetch-car-details-with-bookings' , user]; 
    const { isLoading , data } = useQuery(queryKey , () => {
        return user 
            ? 
                fetcher(`/car/with-future-bookings/${id}?userId=${user?._id}`) 
            : 
                fetcher(`/car/with-future-bookings/${id}`) 
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { car , futureBookings , reviews , settings , insurancePlans , userWallet } } } = data;
            setCarDetails(car);
            setFutureBookings(futureBookings);
            setReviews(reviews);
            setSettings(settings);
            setInsurancePlans(insurancePlans);
            setBookingData(prev => {
                // agr gari sirf with driver available ho tw
                if(car?.hasDriver && !car?.selfDrive) {
                    return {...prev , withDriver : true }
                }
                return prev;
            });
            if(userWallet) {
                const updatedUser = {...user , wallet : userWallet};
                dispatch(setUser(updatedUser));
                localStorage.setItem('user' , JSON.stringify(updatedUser));

            }
        }
    }, [data]);

    useEffect(() => {
        const bookingDetails = JSON.parse(localStorage.getItem('bookingDetails'));
        const discountDetails = JSON.parse(localStorage.getItem('discountDetails'));

        if(bookingDetails && bookingDetails?.carId === id) {
            bookingDetails.pickupDate = new Date(bookingDetails.pickupDate);
            bookingDetails.returnDate = new Date(bookingDetails.returnDate);
            setBookingData(bookingDetails)       
        }
        if(discountDetails && discountDetails?.carId === id) {
            setDiscountDetails(discountDetails)
        }
    } , [])

    const createBooking = async (reqBody) => {
        try {
            const { data : { data : { message , doc } } } = await Axios.post(`/booking/create` , reqBody , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            toast.success(message);
            setBookingLoading(false);
            localStorage.removeItem('bookingDetails');
            localStorage.removeItem('discountDetails');
            localStorage.removeItem('couponCode')
            navigate('/bookings')
        } catch (error) {
            setBookingLoading(false)
            toastError(error);
        }     
    }

    const bookingHandler = async () => {
        if(!user) {
            setCurrentStep('login');
            return setShowAuthPopup(true);
        }
        if(!bookingData?.insurance) {
            return toast.error('Please select your insurance plan.')
        }
        if(bookingData?.pickupDate > bookingData?.returnDate) {
            return toast.error('Invalid date and time selected. Please make sure return date and time should be greater than pickup date and time.' , { autoClose : 5000 })
        }

        
        const body = {
            ...bookingData , 
            carId : id ,
            securityFee : settings?.securityFee 
        };

        if(useWalletBalance) {
            body.usedWalletBalance = Math.min(user?.wallet?.totalBalance, bookingData?.amountToPayOnline);
            body.amountToPayOnline = Math.max(0, bookingData?.amountToPayOnline - user?.wallet?.totalBalance);
        }

        if(discountDetails?.isCouponApplied) {
            body.discountDetails = {
                coupon : discountDetails?.coupon?._id , 
                actualBookingAmount : discountDetails?.actualAmount,
                afterDiscountAmount :  discountDetails?.actualAmount - discountDetails?.discount,
                discount : discountDetails?.discount            
            }
        }

        setBookingLoading(true);
        if(body?.amountToPayOnline > 0) {
            try {
                const { data : { data : { order } } } = await Axios.post(`/booking/init` , body , {
                    headers : {
                        Authorization : `Bearer ${user?.token}`
                    }
                });
    
                var options = {
                    key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
                    amount : bookingData?.totalPrice, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                    currency : "INR",
                    name: "SAK RENT N DRIVE", //your business name
                    description: "Payment for SAK RENT N DRIVE booking",
                    image: "",
                    order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                    handler: async function (response) { 
                        const reqBody = {
                            ...response,
                            ...body
                        };                  
                        await createBooking(reqBody)    
                    },
                    prefill: {
                        //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
                        name: user?.fullName, //your customer's name
                        email: user?.email,
                        contact: user?.phone, //Provide the customer's phone number for better conversion rates
                    },
                    notes: {
                        address: "Razorpay Corporate Office",
                    },
                    theme: {
                        color: "#3399cc",
                    },
                    modal: { 
                        escape: false , 
                        ondismiss : () => setBookingLoading(false)
                    }
                };
    
                const rzp1 = new Razorpay(options);
                rzp1.on("payment.failed", function (response) {
                    toast.error(response.error.reason);
                });
                rzp1.open();
            } catch (error) {
                setBookingLoading(false);
                toastError(error);
            }
        }else { // agr sb amount wallet sy cover ho gai h tw hm razor pay use ni kr skty qk wo error through kry ga 0 amount p tw humein direct booking krni hogi
            await createBooking(body)
        }


    }



    const handleDriverChange = (e) => {
        let withDriver = false;
        if(e.target.checked) {
           withDriver = true ;
        }
        dispatch(calculateBooking({
            bookingData : {...bookingData , withDriver } ,
            setBookingData ,
            discountDetails , 
            setDiscountDetails , 
            carId : id 
       }))
    }

    const kycClickHandler = () => {
        localStorage.setItem('bookingDetails' , JSON.stringify({...bookingData}));
        navigate(`/kyc/step1?ref=book-now&vehicle=${carDetails?._id}`);
    }


    const paymentMethodChangeHandler = (e) => {
        let paymentMethod = 'online';
        if (e.target.value === 'COD') {
            setUseWalletBalance(false); 
            paymentMethod = 'COD'
        }
        dispatch(calculateBooking({
            bookingData : {...bookingData , paymentMethod } ,
            setBookingData ,
            discountDetails , 
            setDiscountDetails , 
            carId : id 
        }))
    }

    return (
        <div>
            <div className='container mx-auto py-4 sm:px-0 px-4'>
                <Breadcrumbs crumbs={crumbs} />
                <div className='flex items-center justify-between gap-4 border-b-2 pb-2 border-secondary py-6'>
                    <h1 className="section-heading text-gradient">
                        Book Car 
                    </h1>
                    <BackBtn />
                </div>
                {
                    isLoading
                    ?
                        <Loader />
                    : 
                        <>
                            <div className='mt-12 flex gap-6 lg:flex-row flex-col'>
                                <div className='flex-[0.7] shadow-bg p-4 flex flex-col justify-between h-fit '>
                                    <div>
                                        <div className='flex items-center gap-2 rounded-md py-3 px-3 bg-green-100 border border-green-500'>
                                            <i className="uil uil-check-circle text-green-500"></i>
                                            <p className='text-sm text-dark'>Free cancellation up to 48 hours before pick-up</p>
                                        </div>
                                        <div>
                                            <CarInfo 
                                            carDetails={carDetails} 
                                            />
                                        </div>
                                        <div className='flex gap-3 items-center mt-6'>
                                            <div className='bg-blue-800 rounded-md py-1.5 px-2 text-sm text-pure'>
                                                {carDetails?.ratingsAvg}
                                            </div>
                                            <div className='flex flex-col '>
                                                <strong className='text-sm font-bold'>
                                                    {
                                                        carDetails?.ratingsCount === 0
                                                        ? 
                                                            'No reviews yet'
                                                        :
                                                        carDetails?.ratingsAvg > 4 
                                                        ? 
                                                            'Excellent'
                                                        : 
                                                        carDetails?.ratingsAvg > 3
                                                        ? 
                                                            'Very Good'
                                                        : 
                                                        carDetails?.ratingsAvg > 2
                                                        ?
                                                            'Not Bad'
                                                        : 
                                                        carDetails?.ratingsAvg > 1
                                                        ? 
                                                            'Not Recomended'
                                                        : 
                                                            'Not Recomended'
                                                    }
                                                </strong>
                                                <a 
                                                href='#book-now-reviews' className='text-grayText underline text-sm'>
                                                    {carDetails?.ratingsCount} {carDetails?.ratingsCount > 1 ? 'Reviews' : 'review'}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="mt-6">
                                            <PickupInstructions 
                                            carDetails={carDetails} 
                                            />
                                        </div>
                                       
                                    </div>
                                    <div>
                                        <div className='flex-[0.15] flex items-center justify-end '>
                                            <h6 className='text-xl font-bold mt-1 mb-2'>Rent Per Day : Rs {carDetails?.rentPerHour * 24}</h6>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className='flex-[0.3] shadow-bg p-3 h-fit'>
                                    <div className='flex flex-col gap-4'>
                                        <PickupDate
                                        bookingData={bookingData}
                                        setBookingData={setBookingData}
                                        futureBookings={futureBookings}
                                        discountDetails={discountDetails}
                                        carDetails={carDetails}
                                        settings={settings}
                                        setDiscountDetails={setDiscountDetails}
                                        />
                                        <ReturnDate
                                        bookingData={bookingData}
                                        setBookingData={setBookingData}
                                        futureBookings={futureBookings}
                                        discountDetails={discountDetails}
                                        settings={settings}
                                        carDetails={carDetails}
                                        setDiscountDetails={setDiscountDetails}
                                        />
                                        <p className='text-sm -mt-2 text-red-500'>
                                            Minimum Booking duration Allowed {settings?.minRentalDuration} hours
                                        </p>
                                        {
                                            <div>
                                                {   
                                                    carDetails?.hasDriver && carDetails?.selfDrive
                                                    ?
                                                    <div className='flex items-center gap-2 text-sm'>
                                                        <input 
                                                        type="checkbox" 
                                                        id='withDriver'
                                                        checked={bookingData?.withDriver}
                                                        onChange={handleDriverChange}
                                                        disabled={!bookingData?.pickupDate || !bookingData?.returnDate}
                                                        />
                                                        <label 
                                                        htmlFor='withDriver'
                                                        className={`${!bookingData?.pickupDate || !bookingData?.returnDate ? 'text-gray-400' : 'text-black'} `}
                                                        >
                                                            Do you need a driver?
                                                        </label>
                                                    </div>
                                                    :
                                                    carDetails?.hasDriver && !carDetails?.selfDrive
                                                    ?
                                                        <div className='flex items-center gap-2 text-sm'>
                                                            <input 
                                                            type="checkbox" 
                                                            checked={bookingData?.withDriver}
                                                            />
                                                            <span>Only available with driver</span>
                                                        </div>
                                                    :
                                                        ''
                                                }
                                            </div>
                                        }

                                        <div className='flex justify-between items-center mt-4'>
                                            <h6 className='font-medium'>
                                                Total Duration
                                            </h6>
                                            <div className='flex gap-2'>
                                                <span>
                                                    {bookingData?.duration?.days}
                                                    {
                                                        bookingData?.duration?.days > 1 ? ' days' : ' day'
                                                    }    
                                                </span>
                                                <span>
                                                    {bookingData?.duration?.hours}
                                                    {
                                                        bookingData?.duration?.hours > 1 ? ' hours' : ' hour'
                                                    }    
                                                </span>
                                                <span>
                                                    {bookingData?.duration?.minutes}
                                                    {
                                                        bookingData?.duration?.minutes > 1 ? ' mins' : ' min'
                                                    }    
                                                </span> 
                                            </div>
                                        </div>
                                        <div className='flex justify-between items-center'>
                                            <h6 className='font-medium'>
                                                Total Hours : 
                                            </h6>
                                            <p>
                                                {bookingData?.duration?.totalHours} hrs
                                            </p>
                                        </div>
                                        <div>
                                            <ApplyCoupon 
                                            discountDetails={discountDetails}
                                            setDiscountDetails={setDiscountDetails}
                                            bookingAmount={bookingData?.totalPrice}
                                            setBookingData={setBookingData}
                                            bookingData={bookingData}
                                            settings={settings}
                                            />
                                        </div>
                                        <div>
                                            <Insurance 
                                            insurancePlans={insurancePlans}
                                            setBookingData={setBookingData}
                                            bookingData={bookingData}
                                            discountDetails={discountDetails}
                                            setDiscountDetails={setDiscountDetails}
                                            />
                                        </div>
                                        <div className='flex justify-between items-center'>
                                            <h6 className='font-medium'>
                                                Total Rent : 
                                            </h6>
                                            <p className='text-lg font-semibold'>
                                                ₹ &nbsp; 
                                                {formatAmount(bookingData?.vehicleRent)}
                                            </p>
                                        </div>
                                        <div className='flex justify-between items-center'>
                                            <h6 className='font-medium'>
                                                Insurance Cost : 
                                            </h6>
                                            <p className='text-lg font-semibold'>
                                                ₹ &nbsp; 
                                                {formatAmount(bookingData?.insuranceCost)}
                                            </p>
                                        </div>
                                        <div className='flex justify-between items-center'>
                                            <h6 className='font-medium'>
                                                Security Fee  
                                                <button 
                                                className='w-[20px] ml-1' 
                                                data-tooltip-id="security-fee"
                                                data-tooltip-content="Refundable"
                                                >
                                                    <i className="uil uil-info-circle"></i>
                                                </button>
                                                <Tooltip id='security-fee' />
                                            </h6>
                                            <p className='text-lg font-semibold'>
                                                ₹ &nbsp; 
                                                {formatAmount(settings?.securityFee)}
                                            </p>
                                        </div>
                                        {
                                            bookingData?.withDriver && 
                                            <div className='flex justify-between items-center'>
                                                <h6 className='font-medium'>
                                                    Driver Fee : 
                                                </h6>
                                                <p className='text-lg font-semibold'>
                                                    ₹ &nbsp; 
                                                    {formatAmount(bookingData?.driverRent)}
                                                </p>
                                            </div>
                                        }
                                        <div className='flex justify-between items-center'>
                                            <h6 className='font-medium'>
                                                GST ({settings?.GST}%): 
                                            </h6>
                                            <p className='text-lg font-semibold'>
                                                ₹ &nbsp; 
                                                {formatAmount(bookingData?.GST)}
                                            </p>
                                        </div>
                                        <div className='flex justify-between items-center'>
                                            <h6 className='font-medium'>
                                                Discount : 
                                            </h6>
                                            <p className='text-lg font-semibold'>
                                                ₹ &nbsp; 
                                                { formatAmount(discountDetails?.discount || 0) }
                                            </p>
                                        </div>
                                        {
                                            bookingData?.pickupDate && bookingData?.returnDate &&
                                            <div className='flex justify-between items-center text-xl'>
                                                <h6 className='font-semibold'>
                                                    Grand Total : 
                                                </h6>
                                                <p className='font-bold'>
                                                    ₹ &nbsp; 
                                                    {formatAmount(bookingData?.totalPrice)}
                                                </p>
                                            </div>
                                        }
                                        {
                                            bookingData?.pickupDate && bookingData?.returnDate && user 
                                            ? 
                                                <>
                                                    <div className='flex justify-between items-center'>
                                                        <h6 className='font-medium'>
                                                            Payment Method : 
                                                        </h6>
                                                        <select
                                                        className='select-box text-lg font-semibold text-primary'
                                                        onChange={paymentMethodChangeHandler}
                                                        value={bookingData?.paymentMethod}
                                                        >
                                                            <option value="online">ONLINE</option>
                                                            <option value="COD">COD</option>
                                                        </select>
                                                    </div>

                                                    {
                                                        user && bookingData?.paymentMethod === 'online' && user?.wallet?.totalBalance > 0 && (
                                                            <div className='flex items-center justify-between shadow-bg p-3'>
                                                                <div className='flex items-start gap-2 '>
                                                                    <input 
                                                                    type="checkbox" 
                                                                    className='mt-[5px]'
                                                                    id='useWalletBalance'
                                                                    onChange={e => {
                                                                        if(e.target.checked) {
                                                                            setUseWalletBalance(true)
                                                                        }else {
                                                                            setUseWalletBalance(false)
                                                                        }
                                                                    }}
                                                                    checked={useWalletBalance}
                                                                    required
                                                                    />
                                                                    <label 
                                                                    htmlFor='useWalletBalance'
                                                                    className='text-sm'
                                                                    >
                                                                        Use Wallet Balance
                                                                    </label>
                                                                </div>
                                                                <p className='font-medium'>
                                                                    {
                                                                        useWalletBalance 
                                                                        ?
                                                                        `₹${formatAmount(Math.max(0, user?.wallet?.totalBalance - bookingData?.amountToPayOnline))}`
                                                                        :
                                                                        `₹${formatAmount(user?.wallet?.totalBalance)}`
                                                                    }
                                                                </p>
                                                            </div>
                                                        )   
                                                    }

                                                    {
                                                        bookingData?.paymentMethod === 'COD' && bookingData?.amountToPayOnline > 0 
                                                        && 
                                                        <p className='pb-2 font-medium text-orange-500'>
                                                            Kindly note that for COD Booking, you have to pay 
                                                            <br /> 
                                                            <span className='text-lg text-primary'>
                                                            Rs. {formatAmount(bookingData?.amountToPayOnline)}
                                                            </span> online. 
                                                        </p> 
                                                        
                                                    }
                                                </>    
                                            : 
                                                ''
                                        }
                                    </div>

                                    {
                                        bookingData?.pickupDate && bookingData?.returnDate && useWalletBalance && bookingData?.paymentMethod === 'online' && (
                                            <div className='flex justify-between items-center sm:text-lg text-base mt-4'>
                                                <h6 className='font-semibold'>
                                                    Remaining Payable: 
                                                </h6>
                                                <p className='font-bold'>
                                                    ₹ &nbsp; 
                                                    {formatAmount(Math.max(0, bookingData?.amountToPayOnline - user?.wallet?.totalBalance))}
                                                </p>
                                            </div>
                                        )
                                    }
                                    <div className='flex items-start gap-2 mt-6'>
                                        <input 
                                        type="checkbox" 
                                        className='mt-[5px]'
                                        id='termsOfService'
                                        onChange={e => {
                                            if(e.target.checked) {
                                                setIsTermsAccepted(true)
                                            }else {
                                                setIsTermsAccepted(false)
                                            }
                                        }}
                                        required
                                        />
                                        <label 
                                        htmlFor='termsOfService'
                                        className='text-sm'
                                        >
                                            By checking this box, you are agreeing to our <a href="/terms-and-conditions" target='_blank' className='underline text-blue-400'>terms of service</a>
                                        </label>
                                    </div>
                                    <div className='mt-12 mb-4 flex items-end justify-end'>
                                        {
                                            // user && !user?.kyc 
                                            // ? 
                                            //     <div>
                                            //         <p className='text-sm sm:text-b[15px] text-red-500'>Dear User, kindly complete your KYC (Know Your Customer) verification to unlock booking privileges. Once your KYC is complete, you can proceed with your bookings hassle-free. Thank you for your cooperation!.</p>
                                            //         <div className="mt-4 flex items-end justify-end">
                                            //             <button 
                                            //             onClick={kycClickHandler}
                                            //             className='btn-primary py-2 px-4'>
                                            //                 Complete KYC
                                            //             </button>
                                            //         </div>
                                            //     </div>
                                            // :
                                                <button 
                                                className="btn-primary py-3 px-4 text-sm"
                                                disabled={!bookingData?.pickupDate || !bookingData?.returnDate || bookingLoading || !isTermsAccepted}
                                                onClick={bookingHandler}
                                                type='submit'
                                                >
                                                    {
                                                        bookingLoading
                                                        ?
                                                            <ClipLoader 
                                                            size={20} 
                                                            color='white' 
                                                            />
                                                        : 
                                                            'Confirm Booking'
                                                    }
                                                </button>
                                        }
                                    </div>
                                    
                                </div>

                            </div>
                            <div className='pt-8' id='book-now-reviews'>
                                <h3 className="section-heading text-gradient" >
                                    Reviews
                                </h3>
                                <div className="mt-8">
                                    {
                                        reviews?.length > 0 
                                        ? 
                                            <Reviews
                                            reviews={reviews}
                                            />
                                        :
                                            <ItemNotFound message='No review found' />
                                    }
                                </div>
                            </div>                          
                        </>
                }
            </div>

            {
                couponLoading && <div className='fixed top-0 left-0 bg-black bg-opacity-50 w-full h-screen'></div>
            }
            {
                calculateLoading && <div className='fixed top-0 left-0 bg-black bg-opacity-50 w-full h-screen z-[9999]'></div>
            }
        </div>
    )
}

export default BookNow;